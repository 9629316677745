"use client";

import classNames from "classnames/bind";
import styles from "./LogoStage.module.css";

const cx = classNames.bind(styles);

export const LogoStage = () => {
  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1197.08 394.87">
          <defs>
            <filter
              id="luminosity-noclip"
              x="22.61"
              y="291.05"
              width="139.81"
              height="112.46"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodColor="#fff" result="bg" />
              <feBlend in="SourceGraphic" in2="bg" />
            </filter>
            <mask
              id="mask"
              x="22.61"
              y="291.05"
              width="139.81"
              height="112.46"
              maskUnits="userSpaceOnUse"
            >
              <g filter="url(#luminosity-noclip)">
                <g transform="translate(0 0)">
                  <g id="p-loop-opacity-mask">
                    <path
                      id="p-loop-opacity-path"
                      d="M22.61,311.05H98.43c44,0,44,29.12,44,36.64,0,9.39-.95,35.82-44,35.82H22.61"
                      fill="none"
                      stroke="#000"
                      strokeMiterlimit="10"
                      strokeWidth="40"
                    />
                  </g>
                </g>
              </g>
            </mask>
            <filter
              id="luminosity-noclip-2"
              x="362.74"
              y="293.53"
              width="137.08"
              height="107.46"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodColor="#fff" result="bg" />
              <feBlend in="SourceGraphic" in2="bg" />
            </filter>
            <mask
              id="mask-2"
              x="362.74"
              y="293.53"
              width="137.08"
              height="107.46"
              maskUnits="userSpaceOnUse"
            >
              <g filter="url(#luminosity-noclip-2)">
                <g transform="translate(0 0)">
                  <path
                    d="M362.75,311h75.58c44,0,44,29.12,44,36.65,0,9.39-1,35.81-44,35.81H362.74"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit="10"
                    strokeWidth="35"
                  />
                </g>
              </g>
            </mask>
            <filter
              id="luminosity-noclip-3"
              x="1054"
              y="291.03"
              width="140.37"
              height="112.46"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodColor="#fff" result="bg" />
              <feBlend in="SourceGraphic" in2="bg" />
            </filter>
            <mask
              id="mask-3"
              x="1054"
              y="291.03"
              width="140.37"
              height="112.46"
              maskUnits="userSpaceOnUse"
            >
              <g filter="url(#luminosity-noclip-3)">
                <g transform="translate(0 0)">
                  <path
                    d="M1054,311h76.38c44,0,44,29.12,44,36.65,0,9.39-1,35.81-44,35.81H1054"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit="10"
                    strokeWidth="40"
                  />
                </g>
              </g>
            </mask>
            <clipPath id="clip-path" transform="translate(0 0)">
              <rect id="main-mask" width="1197.08" height="395.3" fill="none" />
            </clipPath>
          </defs>
          <g id="legs-group">
            <g mask="url(#mask)">
              <path
                id="p-loop"
                d="M22.7,299.75l58,0,25.66.29c15.16,1.31,27,6.43,35.26,15.23,8.43,8.8,12.7,20.19,12.7,33.86a42,42,0,0,1-14.39,32.44c-9.55,8.8-22.55,13.26-38.65,13.26H22.7V372.22l78.55,0c9.16,0,16.37-2.09,21.41-6.2,5.24-3.92,7.89-9.89,7.89-17.74,0-8.22-2.84-14.67-8.45-19.15-5.42-4.49-13.38-6.76-23.67-6.76H22.7"
                transform="translate(0 0)"
              />
            </g>
            <polygon
              id="r1-leg"
              points="420.91 299.78 478.4 197.39 478.46 197.3 505.06 197.15 505.21 197.37 446.58 300.06 446.57 300.19 420.88 300.01 420.91 299.78"
            />
            <g mask="url(#mask-2)">
              <path
                id="r1-loop"
                d="M362.82,394.8V372.22l78.67,0c9.16,0,16.37-2.09,21.41-6.2,5.23-3.92,7.89-9.89,7.89-17.74,0-8.22-2.85-14.67-8.45-19.15-5.42-4.49-13.39-6.76-23.67-6.76H362.82V299.75l58.09,0,25.66.29c15.16,1.31,27,6.43,35.26,15.23,8.43,8.8,12.7,20.19,12.7,33.86a42,42,0,0,1-14.39,32.44c-9.55,8.8-22.55,13.26-38.65,13.26H362.82Z"
                transform="translate(0 0)"
              />
            </g>
            <g mask="url(#mask-3)">
              <path
                id="r2-loop"
                d="M1054.75,394.8V372.22l78.55,0c9.16,0,16.37-2.09,21.42-6.2,5.23-3.92,7.88-9.89,7.88-17.74,0-8.22-2.84-14.67-8.45-19.15-5.42-4.49-13.38-6.76-23.67-6.76h-75.73V299.75l58,0,25.66.29c15.16,1.31,27,6.43,35.26,15.23,8.43,8.8,12.7,20.19,12.7,33.86A42,42,0,0,1,1172,381.54c-9.55,8.8-22.55,13.26-38.65,13.26h-78.55Z"
                transform="translate(0 0)"
              />
            </g>
            <polygon
              id="r2-leg"
              points="1112.72 299.78 1170.22 197.39 1170.22 197.11 1196.9 197.23 1197.03 197.37 1138.39 300.06 1138.38 300.36 1112.69 300.04 1112.72 299.78"
            />
            <g id="group-e2">
              <polygon
                id="e2"
                points="886.43 322.34 886.4 299.75 984.8 299.72 984.83 322.34 886.43 322.34"
              />
              <polygon
                id="e1"
                points="886.43 394.8 886.4 372.22 996.08 372.19 996.11 394.8 886.43 394.8"
              />
            </g>
            <polygon
              id="t"
              points="654.66 372.19 591.5 372.19 579.94 372.22 579.94 372.19 568.95 372.19 505.2 372.22 505.23 394.8 580.09 394.8 580.11 394.8 654.69 394.8 654.66 372.19"
            />
            <polygon
              id="n"
              points="705.43 351.92 800.45 197.39 801.46 197.43 801.56 240.23 800.79 240.26 705.43 394.87 704.76 394.8 704.76 351.92 705.43 351.92"
            />
            <polygon
              id="a-left"
              points="205.41 240.26 205.41 240.23 181.78 240.27 181.78 262.84 205.41 262.84 205.43 262.84 229.03 262.84 229.03 240.23 205.41 240.26"
            />
            <polygon
              id="a-right"
              points="250.67 240.26 250.67 240.23 227.03 240.27 227.03 262.84 250.67 262.84 250.68 262.84 274.29 262.84 274.29 240.23 250.67 240.26"
            />
          </g>
          <g id="main-group">
            <path
              d="M139.9,13.26C130.35,4.46,117.35,0,101.25,0L.28,0,0,197.4H0L.31,394.8H22.9V299.75l-.31-102.32h0l.26-102.35h57.8l25.66-.28c15.16-1.31,27-6.44,35.26-15.24,8.43-8.8,12.7-20.19,12.7-33.85A42.05,42.05,0,0,0,139.9,13.26ZM122.1,65.7c-5.42,4.49-13.38,6.76-23.67,6.76l-75.53,0V22.61h78.35c9.16,0,16.37,2.09,21.41,6.2,5.24,3.92,7.89,9.89,7.89,17.74C130.55,54.77,127.71,61.22,122.1,65.7Z"
              transform="translate(0 0)"
            />
            <path
              d="M314.66,197.43,239.33,0,216.75,0,141.5,197.41h0l75.3,197.39h22.58l75.24-197.37ZM228.05,32l37.79,100-75.54,0Zm37.76,230.84-37.76,100-37.75-100-8.46-22.56-16.08-42.84h0l16-42.84,92.46,0,16.08,42.84h0Z"
              transform="translate(0 0)"
            />
            <polygon points="591.25 197.43 591.5 22.61 654.69 22.59 654.66 0 505.2 0.03 505.23 22.61 568.92 22.59 568.67 197.4 568.64 197.4 568.95 394.8 591.53 394.8 591.22 197.43 591.25 197.43" />
            <path
              d="M446.57,94.8c15.16-1.32,27-6.44,35.26-15.24,8.43-8.8,12.7-20.19,12.7-33.85a42.05,42.05,0,0,0-14.39-32.45C470.59,4.46,457.59,0,441.49,0l-101,0-.25,197.37h0l.31,197.4h22.58V299.75l-.31-102.35h0l.26-102.32,57.77,0,57.55,102.37h26.83ZM363.13,72.49V22.61h78.36c9.16,0,16.37,2.09,21.41,6.2,5.23,3.92,7.89,9.89,7.89,17.74,0,8.23-2.85,14.67-8.45,19.15-5.42,4.49-13.39,6.76-23.67,6.76Z"
              transform="translate(0 0)"
            />
            <polygon points="823.34 197.43 823.32 0 800.73 0.03 800.78 154.53 705.45 0 682.87 0.03 682.87 185.35 682.9 197.4 682.87 197.4 682.9 394.8 705.48 394.8 705.48 214.3 705.46 197.4 705.48 197.4 705.48 185.35 705.43 42.9 800.46 197.4 800.45 197.4 800.73 240.26 800.76 394.8 823.34 394.8 823.34 199.9 823.32 197.43 823.34 197.43" />
            <polygon points="886.68 95.08 984.83 95.05 984.8 72.47 886.71 72.49 886.68 22.61 996.11 22.59 996.08 0 864.1 0.03 863.81 174.77 863.86 197.4 863.83 197.4 864.12 394.8 886.71 394.8 886.71 293.97 886.4 197.43 996.06 197.43 996.06 174.74 886.43 174.77 886.68 95.08" />
            <path
              d="M1197.08,197.43,1138.38,94.8c15.17-1.31,27-6.44,35.27-15.24,8.42-8.8,12.7-20.19,12.7-33.85A42.08,42.08,0,0,0,1172,13.26C1162.41,4.46,1149.4,0,1133.31,0l-101,0-.25,197.37h0l.31,197.4H1055V299.75l-.31-102.35h0l.25-102.32,57.77,0,57.55,102.37h26.84ZM1055,72.49V22.61h78.36c9.16,0,16.36,2.09,21.41,6.2,5.23,3.92,7.88,9.89,7.88,17.74,0,8.23-2.84,14.67-8.45,19.15-5.42,4.49-13.38,6.76-23.66,6.76Z"
              transform="translate(0 0)"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};
